import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import './imageMedia.css';

export const ImageMediaEco = () => {
  const {
    mobileImage,
    desktopImage,
  } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "ecographic_768_width.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "ecographic_large.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1348, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
  `);

  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: '(min-width: 769px)',
    },
  ];
  return (
    <>
      <Img
        fluid={sources}
        className={'eco-graphic'}
        objectFit="contain"
      />
    </>
  );
};
