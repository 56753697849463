import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './bgimg.css';
import BackgroundImage from 'gatsby-background-image-es5';

export const TurtleBackground: React.FC<{
  className: any,
}> = ({ className }) => {
  const {
    turtle,
    turtleWide
  } = useStaticQuery(
    graphql`
      query {
        turtle: file(
          relativePath: { eq: "turtle768.jpg" }
        ) {
          childImageSharp {
            fluid(
              quality: 90,
              maxWidth: 768,
              srcSetBreakpoints: [ 414, 768 ]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        turtleWide: file(
          relativePath: { eq: "turtle1920.jpg" }
        ) {
          childImageSharp {
            fluid(
              quality: 90,
              maxWidth: 1920,
              srcSetBreakpoints: [768, 1024, 1440, 1920]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const backgroundFluidImageStack = [
    turtle.childImageSharp.fluid,
    {
      ...turtleWide.childImageSharp.fluid,
      media: '(min-width: 769px)',
    }
  ];

  return (
    <BackgroundImage
      Tag={'div'}
      className={className}
      fluid={backgroundFluidImageStack}
      style={{
        position: 'absolute',
        backgroundPosition: 'center bottom',
        objectFit: 'contain',
        backgroundSize: 'contain',
        opacity: '1',
      }}
    >
    </BackgroundImage>
  );
};
