import * as React from 'react';
import { navigate } from 'gatsby';
import { SearchForm } from '../components/searchform/searchform';
import { useState } from 'react';
import { useDataList } from '../hooks/data-list';
import { TurtleBackground } from '../components/bgimg/turtlebgimg';
import { ImageMediaEco } from '../components/imageMedia/imageMedia';
import Helmet from 'react-helmet';

// Build 1602588263

const IndexPage: React.FC<{}> = () => {
  // Load list of data.
  const { edges } = useDataList();
  let initialFilters = { types: [], services: [] };
  const [filters, setFilters] = useState(initialFilters);
  const services = edges.map(row => row.node.service.trim()).sort();
  const types = edges.map(row => row.node.typeOfHabitat.trim()).sort();

  // Change filters values.
  const changeFilter = (filters: any) => {
    setFilters({ types: filters.types, services: filters.services });
  };

  return (
    <>
      <Helmet titleTemplate="BlueValue" />
      <section className="header--pre">
        <div className="inner inner-padding">
          <h2 className="text-center">One Ecosystem.<br />Connecting the World.</h2>
          <p className="text-center header--intro">From inland watersheds to our coastal communities to the deep ocean, human well-being is tightly connected to our natural environment. BlueValue illuminates the importance that we place on these ecosystems  — their value — by sharing the latest science and information.</p>
        </div>
      </section>

      <section className="header--search clearfix text spacing-bottom--diver section--diver">
        <div className="inner inner-padding">
          <p className="py-5 text-center header--search--instructions">To begin, select or search our database.</p>
          <form
            onSubmit={event => {
              event.preventDefault();
              navigate('/search/', {
                state: { filters },
              });
            }}
          >
            <label className="text-left">You can select more than one service or type.</label>
            <SearchForm
              filters={filters}
              changeFilter={changeFilter}
              services={services}
              types={types}
            />

            <button type="submit" className="w-1/6 btn btn-green ml-2 px-6">
              Show Results
            </button>
          </form>
        </div>
      </section>

      <section className="relative bg-spacing--extra section--turtle">
        <TurtleBackground
          className={'full-bg turtle'}
        />
        <div className="inner inner-padding">
          <h3 className="text-center">A Closer Look at Nature&apos;s Benefits</h3>
          <p className="text-center text--intros--no-mb"> Humans benefit from a variety of products and services provided by the environment, these are called ecosystem services.</p>

          <div className="spacer--vert">&nbsp;</div>
        </div>
        <div className="inner px-1 lg:px-0">
          <ImageMediaEco />
        </div>
      </section>
    </>
  );
};

export default IndexPage;
